var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-card"},[_c('router-view'),_c('div',{staticClass:"list-top-section px-20"},[_c('div',{staticClass:"list-top-section--search"},[_c('searchbar',{on:{"search":function($event){return _vm.pageChangeHandle(1, $event)}}})],1),_c('div',{staticClass:"list-top-section--create"},[(_vm.access('core', 'create_assets'))?_c('a',{on:{"click":function($event){return _vm.open_form(_vm.AssetFormComponent, { header: 'Create Asset for' })}}},[_c('div',{staticClass:"sh-button sh-button--create sh-button--inverted is-semiBold-14-600-17"},[_vm._v(" "+_vm._s(_vm.localization("app-new-asset", "New Asset"))+" ")])]):_c('a',{on:{"click":function($event){return _vm.no_access_popup('core', 'create_assets')}}},[_c('div',{staticClass:"sh-button sh-button--create disabled-link is-semiBold-14-600-17"},[_vm._v(" "+_vm._s(_vm.localization("app-new-asset", "New Asset"))+" ")])])])]),(_vm.data && _vm.data.length && !_vm.isLoading)?_c('div',[_c('list-header'),_c('div',{staticClass:"sh-list"},_vm._l((_vm.data),function(item){return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.hover == item.uid ? (_vm.hover = -1) : null)),expression:"() => (hover == item.uid ? (hover = -1) : null)"}],key:item.id,staticClass:"sh-list__item",on:{"mouseleave":function($event){_vm.hover = -1},"mouseenter":function($event){_vm.hover = item.uid}}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3_5"},[_c('router-link',{staticClass:"has-text-dark",attrs:{"to":{ name: 'asset-details', params: { id: item.uid } }}},[_c('main-details-media',{attrs:{"truncate_value":26,"show_tooltip":true,"info":{
                  name: item.name,
                  subtext: item.asset_type
                    ? item.asset_type
                    : '*Type not found',
                  name_class:
                    'is-semiBold-16-600-19 has-primary-text name-highlights-on-hover',
                  subtext_class:
                    'is-medium-14-500-17 has-primary-text is-capitalized',
                  properties: item.properties,
                }}})],1)],1),_c('div',{staticClass:"column is-2_5 is-flex align-center"},[_c('user-avatar',{attrs:{"user":item.owner,"pxsize":30}}),(item.owner)?_c('span',{directives:[{name:"username",rawName:"v-username",value:(item.owner),expression:"item.owner"}],staticClass:"is-medium-14-500-17 has-text-new-greys truncate-text ml-5"}):_c('span',{staticClass:"is-regular-14-00-17 has-opacity-6 has-text-new-grey truncate-text ml-5"},[_vm._v("*Not found")])],1),_c('div',{staticClass:"column is-3 is-flex align-center"},[_c('primary-tags',{staticClass:"is-marginless no-flex-wrap",attrs:{"tags":item.labels,"toShowCount":2,"type":'team',"truncate_length":6}})],1),_c('div',{staticClass:"column is-3 is-flex is-between align-center"},[(item.tags && item.tags.length)?_c('assigned-tags',{staticClass:"mt-1",attrs:{"tags":item.tags,"write_access":false,"number_of_tags_to_display":3}}):_c('div',{staticClass:"is-flex align-center"},[_c('sh-icon',{staticClass:"is-20x20 mr-5 has-opacity-6",attrs:{"name":'tags'}}),_c('span',{staticClass:"is-regular-14-00-17 has-opacity-6"},[_vm._v(_vm._s(_vm.localization("app-no-tags-assigned", "*No tags assigned")))])],1),_c('div',{staticClass:"transfer-ownership"},[_c('transfer-owner',{ref:'assetList' + item.uid,refInFor:true,attrs:{"name":item.name,"service":_vm.assetService,"id":item.uid},on:{"update_user":function($event){return _vm.update_owner(item, $event)}}})],1),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
                () => (_vm.dropdown == item.uid ? (_vm.dropdown = -1) : null)
              ),expression:"\n                () => (dropdown == item.uid ? (dropdown = -1) : null)\n              "}],staticClass:"asset-dropdown"},[_c('div',{staticClass:"asset-dropdown-selection",class:{
                  'is-hidden': _vm.hover != item.uid && _vm.dropdown != item.uid,
                },on:{"click":function($event){_vm.dropdown == -1 ? (_vm.dropdown = item.uid) : (_vm.dropdown = -1)}}},[_c('sh-icon',{staticClass:"is-20x20",attrs:{"name":'3-dots-menu'}})],1),(_vm.dropdown == item.uid)?_c('div',{staticClass:"asset-dropdown-content"},[_c('p',{attrs:{"tag":"p"},on:{"click":function($event){return _vm.open_form(
                      _vm.AssetFormComponent,
                      { header: 'Edit Asset for' },
                      item
                    )}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'edit-small'}}),_c('span',{staticClass:"no-whitespace-wrap"},[_vm._v(_vm._s(_vm.localization("app-edit", "Edit"))+" ")])],1),_c('p',{on:{"click":function($event){return _vm.open_transfer_popup(item.uid)}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'transfer-2'}}),_c('span',{staticClass:"no-whitespace-wrap"},[_vm._v(" "+_vm._s(_vm.localization( "app-transfer-ownership", "Transfer ownership" )))])],1),_c('p',{on:{"click":function($event){_vm.showDeleteModal = true;
                    _vm.currentAsset = item;}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'delete-small'}}),_c('span',{staticClass:"no-whitespace-wrap"},[_vm._v(_vm._s(_vm.localization("app-delete-asset", "Delete Asset")))])],1)]):_vm._e()])],1)])])}),0)],1):_c('div',[(_vm.isLoading)?_c('div',[_c('list-header'),_c('list-skeleton')],1):_c('no-results',[_c('img',{attrs:{"slot":"image","src":require("@/assets/illustrations/assets-illustration.svg"),"alt":""},slot:"image"}),(_vm.access('core', 'create_assets'))?_c('div',{attrs:{"slot":"button"},slot:"button"},[_c('a',{on:{"click":function($event){return _vm.open_form(_vm.AssetFormComponent, { header: 'Create Asset for' })}}},[_c('div',{staticClass:"sh-button sh-button--create"},[_vm._v(" "+_vm._s(_vm.localization("app-create-new-asset", "Create New Asset"))+" ")])])]):_vm._e()])],1),_c('pagination',{staticClass:"articles-list__pagination",attrs:{"default_page_size":_vm.default_page_size,"current-page":_vm.currentPage,"page-count":_vm.pageCount,"visiblePagesCount":_vm.visiblePagesCount,"totalRecords":_vm.totalRecords},on:{"nextPage":function($event){return _vm.pageChangeHandle('next')},"previousPage":function($event){return _vm.pageChangeHandle('previous')},"loadPage":_vm.pageChangeHandle}}),(_vm.showDeleteModal)?_c('delete-modal',{attrs:{"resourceId":_vm.currentAsset.uid,"resourceName":_vm.currentAsset.name,"resourceType":'asset',"resourceService":_vm.assetService},on:{"closed":function($event){_vm.showDeleteModal = false},"deleted":function($event){_vm.show_success('Resource successfully deleted');
      _vm.getData();}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }